import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import { Statistic, Card  ,Col,
  Row} from "antd";





function PageDashboardAdmin({ data, getData, REFRESH, addData, updateData }) {


  useEffect(() => {
    // getData({ query: "" });
  }, [REFRESH]);



  return (
    <div className="site-page-header-ghost-wrapper">
      <Row gutter={16}>
        <Col span={12}>
          <Card>
            <Statistic
              title="Online Sales ( Today )"
              value={1200}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<DollarCircleOutlined />}
              prefix="$"
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title="Active Slots"
              value={24}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<ArrowDownOutlined />}
              suffix=""
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state?.organiser?.list,
  REFRESH: state?.levent?.DASHBOARD_REFRESH,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(PageDashboardAdmin);
