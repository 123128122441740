import {requestStart, requestFinish, getApi,postApi,updateApi} from './common';
import {API_BASE_URL , PATH_USERS,PATH_EMAIL_GROUP} from '../config';

const ACTION_META_NAME = "USERS";



export  function fetchEmailGroup() {

  return async function(dispatch) {
   


    const data = await getApi(dispatch, {
      url : `${API_BASE_URL}${PATH_EMAIL_GROUP}`
    });
    console.log("api called", data)

    if(data!=null){
      dispatch({
        type: 'EMAIL_GROUP_LIST',
        data:data,
        receivedAt: Date.now()
      });
    }

    dispatch({
      type:'NOTIFICATION_HIDE',
      data:{
        title:'',
        type:'success',
        body:''
      }
    });
 
  }
}
export  function fetchData(reqData, type= "", isDense = false) {

  return async function(dispatch) {
   


    let QUERY_PARAMS = '';

    if(reqData.query){
      QUERY_PARAMS = `?query=${reqData.query}`;
    }

    if(isDense){
      if(QUERY_PARAMS == ""){
        QUERY_PARAMS = `?dense=1`
      }else{
        QUERY_PARAMS = `${QUERY_PARAMS}&dense=1}`

      }
    }

    const data = await getApi(dispatch, {
      url : `${API_BASE_URL}${PATH_USERS}s/${type}${QUERY_PARAMS}`
    });
    console.log("api called", data)

    if(data!=null){
      dispatch({
        type: ACTION_META_NAME+'_LIST',
        data:data,
        receivedAt: Date.now()
      });
    }

    dispatch({
      type:'NOTIFICATION_HIDE',
      data:{
        title:'',
        type:'success',
        body:''
      }
    });
 
  }
}



export  function createData(reqData, type= "") {

  return async function(dispatch) {
   

    const data = await postApi(dispatch, {
      url : `${API_BASE_URL}${PATH_USERS}`,
      body: reqData
    });

    if(data!=null){

      dispatch({
        type:'NOTIFICATION_SHOW',
        data:{
          title: ACTION_META_NAME + ' Created',
          type:'success',
          body:''
        }
      });

      dispatch({
        type:ACTION_META_NAME+'_CREATED',
        data:data,
        receivedAt: Date.now()
      });

      dispatch({
        type:'FIRE_EVENT',
        data:{
          event: ACTION_META_NAME+'_REFRESH'
        }
      });


    }
 
  }
}

export  function putData(reqData, type= "") {

  return async function(dispatch) {
   

    const data = await updateApi(dispatch, {
      url : `${API_BASE_URL}${PATH_USERS}/${reqData.user_id}`,
      body: reqData
    });

    if(data!=null){

      dispatch({
        type:'NOTIFICATION_SHOW',
        data:{
          title:'User Updated',
          type:'success',
          body:''
        }
      });

      dispatch({
        type: ACTION_META_NAME+'_UPDATED',
        data:data,
        receivedAt: Date.now()
      });

      dispatch({
        type:'FIRE_EVENT',
        data:{
          event: ACTION_META_NAME+'_REFRESH'
        }
      });


    }
 
  }
}
