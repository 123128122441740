export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const PATH_LOOKUP = "api/lookup";
export const PATH_DASHBOARD = "api/dashboard";
export const PATH_PRODUCT = "api/products?";
export const PATH_PRODUCT_CREATE = "product";

export const PATH_CATEGORY = "api/category";

export const PATH_ORDER = "api/orders";
export const PATH_USERS = "user";
export const PATH_EMAIL_GROUP = "emailgroup";

export const PATH_CAT = "manage/category";
export const PATH_UNIT = "manage/unit";

export const PATH_SUBCAT = "manage/subcategory";
export const PATH_LOWCAT = "manage/lowcategory";
export const PATH_ETSY_SYNC = "sync/etsy";
export const PATH_ETSY_GOLD_SYNC = "sync/etsy_gold";

export const PATH_SHOPIFY_SYNC = "sync/shopify";
export const PATH_SHOPIFY_TJ_SYNC = "sync/shopify_tj";

export const PATH_AUTH = "auth/admin/login";
export const PATH_AUTH_REFRESH = "auth/admin/refresh";

export const PATH_BANNER = "api/banner";

export const PATH_MANAGE = "manage/";



export const PATH_API_MANAGE = "api/";
export const PATH_PRODUCTS = "product"