import fetch from 'cross-fetch'
import {requestStart, requestFinish,getApi,postApi,updateApi} from './common';
import {API_BASE_URL , PATH_CATEGORY,PATH_CAT,PATH_LOWCAT,PATH_SUBCAT,PATH_LOOKUP,PATH_UNIT} from '../config';


export const GET_CATEGORY = 'GET_CATEGORY';
export const CREATE_OR_UPDATE_CATEGORY = 'CREATE_OR_UPDATE_CATEGORY';

function recvData(json) {


  return {
    type: GET_CATEGORY,
    data:json.category,
    receivedAt: Date.now()
  }
}


function postData(isCreate , json) {


  return {
    type: CREATE_OR_UPDATE_CATEGORY,
    data:json,
    message : isCreate,
    receivedAt: Date.now()
  }
}



export function fetchData(query) {



  return function(dispatch) {
   
    dispatch(requestStart())
 
    return fetch(`${API_BASE_URL}${PATH_LOOKUP}`,{
      method: 'GET', 
    })

      .then(
        response => response.json(),
       
        error => {
          throw new Error("Whoops!");
        }
      )
      .then(json => {
        dispatch(recvData(json));

        dispatch(requestFinish());

        dispatch(  {
          type: 'UNIT_LIST',
          data:json.unit,
          receivedAt: Date.now()
        });

        dispatch({
          type:'NOTIFICATION_HIDE',
          data:{
            title:'',
            type:'success',
            body:''
          }
        });


      }
       
      ).catch(error => {
        dispatch(requestFinish());

      })
  }
}


export function createOrUpdateUnit(reqData) {



  return async function(dispatch) {

    const data = await postApi(dispatch, {
      url : `${API_BASE_URL}${PATH_UNIT}`,
      body: reqData
    });
  
    if(data!=null){
  
  
      dispatch({
        type:'NOTIFICATION_SHOW',
        data:{
          title:'Unit Created',
          type:'success',
          body:''
        }
      });

      dispatch(fetchData());
    }else{
      dispatch({
        type:'NOTIFICATION_SHOW',
        data:{
          title:'Cannot create unit',
          type:'error',
          body: ''
        }
      });
    }

    
 
   
  }
}



export function createOrUpdate(isCreate ,data) {



  return function(dispatch) {
   
    dispatch(requestStart())
 
    return fetch(`${API_BASE_URL}${PATH_CATEGORY}`,{
      method: isCreate ? 'POST' : 'PUT', 
      body:JSON.stringify(data),
      headers:{
        'Content-Type':'application/json'
      }
    })

      .then(
        response => response.json(),
       
        error => {
          throw new Error("Whoops!");
        }
      )
      .then(json => {
        dispatch(postData(isCreate, json));

        dispatch(requestFinish());

      }
       
      ).catch(error => {
        dispatch(requestFinish());

      })
  }
}




export  function createCategory(reqData) {

  return async function(dispatch) {
   

    const data = await postApi(dispatch, {
      url : `${API_BASE_URL}${PATH_CAT}`,
      body: reqData
    });

    if(data!=null){

      dispatch({
        type:'NOTIFICATION_SHOW',
        data:{
          title:'Category Created',
          type:'success',
          body:''
        }
      });
      
      dispatch(fetchData());
    }
 
  }
}

export  function updateCategory(uniId, reqData) {

  return async function(dispatch) {
   

    const data = await updateApi(dispatch, {
      url : `${API_BASE_URL}${PATH_CAT}/${uniId}`,
      body: reqData
    });


    console.log("user updated", data);
    if(data!=null){

      dispatch({
        type:'NOTIFICATION_SHOW',
        data:{
          title:'Category Update',
          type:'success',
          body:''
        }
      });
      dispatch(fetchData());

    }
 
  }
}



export  function createSubCategory(reqData) {

  return async function(dispatch) {
   

    const data = await postApi(dispatch, {
      url : `${API_BASE_URL}${PATH_SUBCAT}`,
      body: reqData
    });

    if(data!=null){

      dispatch({
        type:'NOTIFICATION_SHOW',
        data:{
          title:'Sub Category Created',
          type:'success',
          body:''
        }
      });
      
      dispatch(fetchData());
    }
 
  }
}

export  function updateSubCategory(uniId, reqData) {

  return async function(dispatch) {
   

    const data = await updateApi(dispatch, {
      url : `${API_BASE_URL}${PATH_SUBCAT}/${uniId}`,
      body: reqData
    });


    console.log("user updated", data);
    if(data!=null){

      dispatch({
        type:'NOTIFICATION_SHOW',
        data:{
          title:'Sub Category Update',
          type:'success',
          body:''
        }
      });
      dispatch(fetchData());

    }
 
  }
}



export  function createLowCategory(reqData) {

  return async function(dispatch) {
   

    const data = await postApi(dispatch, {
      url : `${API_BASE_URL}${PATH_LOWCAT}`,
      body: reqData
    });

    if(data!=null){

      dispatch({
        type:'NOTIFICATION_SHOW',
        data:{
          title:'Low Category Created',
          type:'success',
          body:''
        }
      });
      
      dispatch(fetchData());
    }
 
  }
}

export  function updateLowCategory(uniId, reqData) {

  return async function(dispatch) {
   

    const data = await updateApi(dispatch, {
      url : `${API_BASE_URL}${PATH_LOWCAT}/${uniId}`,
      body: reqData
    });


    console.log("user updated", data);
    if(data!=null){

      dispatch({
        type:'NOTIFICATION_SHOW',
        data:{
          title:'Low Category Update',
          type:'success',
          body:''
        }
      });
      dispatch(fetchData());

    }
 
  }
}

