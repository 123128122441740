import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

// import DataProduct from "../component/lists/Products";
// import FilterModelProducts from "../component/filters/Products";

import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  DatePicker,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { Table, Tag, Space, Popconfirm, message, Image, Switch } from "antd";

import { PageHeader, Descriptions } from "antd";
import { useHistory } from "react-router-dom";

import { fetchData, createData, putData, fetchEmailGroup } from "../actions/users";
import SectionSelect from '../component/SectionGroupSelect';
import ModalFilterCommon from '../component/modal/ModalFilterCommon';


const { Option } = Select;

function PageUsers({ data, getData, addData, updateData, REFRESH, emailGroup, getEmailGroup }) {


  const [editData, updateEditData] = React.useState({});

  const [showFilter, toggleFilter] = React.useState(false);

  const [showDrawer, toggleDrawer] = React.useState(false);

  const [formValues, updateFormValues] = useState({ role_type: 1, isFormEdit: false });

  const [filters, setFilters] = React.useState({});


  const history = useHistory();

  const updateDrawer = () => {
    toggleDrawer(!showDrawer);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    getData(filters);
    getEmailGroup();
  }, [REFRESH]);


  const [emailGroups , setEmailGroups] = useState("");

  const onEmailGroupSelect = (data) => {
    console.log(data);

    setEmailGroups(data.join(","));
  }


  const onFormSubmit = (values) => {
    console.log("form data", values);


    console.log("Received values of form: ", values);
  

    let payload = {
      ...values
    };


    if(values?.isFormEdit)
    updateData({...payload, user_id: values.uniId, email_group : emailGroups});
    else
    addData({...payload, email_group : emailGroups});

  };

  const onUpdateItem = (data, updates) => {
    updateData({ ...data, ...updates });
  };

  const onActionItem = (data) => {
    updateFormValues({ ...data, isFormEdit: true, uniId: data.user_id });
    form.setFieldsValue({ ...data, isFormEdit: true, uniId: data.user_id });
    updateDrawer();

    // updateProduct(data.product_id, {...data, is_private: !data.is_private});
  };

  const onEditClicked = (data) => {
    console.log("edit clicked", data);


    updateEditData({
      ...data,
      uniId: data?.user_id,
      isFormEdit: true
    });
    toggleDrawer(true);
  }


  useEffect(() => {

    form.setFieldsValue(editData);

  }, [
    editData
  ])

  const onToggleItem = (data, record, index) => {

    console.log(data)

    data['email_group'] = data?.email_group?.group_ids
    updateData({...data, status: !data.status});


  };

  const onDeleteItem = (data) => {

    data['email_group'] = data?.email_group?.group_ids

    updateData({...data, status: !data.status});

  };



  const toggleFilterDialog = (data) => {

    console.log(data);

    toggleFilter(false);

    if (data) {
      setFilters(data);
      getData(data);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    // {
    //   title: 'Gender',
    //   dataIndex: 'gender',
    //   key: 'gender',
    // },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: 'Tax ID',
      dataIndex: 'tax',
      key: 'tax',
    },
    {
      title: 'Timestamp',
      dataIndex: 'created',
      key: 'created',
    },

    {
      title: 'Verify',
      key: 'status',
      render: (item, record, index) => (
        <Space size="middle">
          <Switch checked={item.status} onChange={() => onToggleItem(item, record, index)} />
        </Space>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (record) => (
        <Space size="middle">
          <a onClick={() => onEditClicked(record)}>Edit</a>
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() => onDeleteItem(record)}
            onCancel={() => { }}
            okText="Yes"
            cancelText="No"
          >
            <a href="#" style={{ color: 'red' }}>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];


  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        title="Users"
        className="site-page-header"
        extra={[
          <Button key="3" onClick={() => { 

            setFilters({});
            getData({});

          }}>
            Clear Filter
          </Button>,

          <Button key="2" onClick={() => toggleFilter(true)}>
            Filters
          </Button>,

          <Button
            key="1"
            type="primary"
            onClick={() => {
              form.resetFields();
              updateFormValues({});
              updateDrawer();
            }}
          >
            Add
          </Button>,
        ]}
      ></PageHeader>
      <Table columns={columns} dataSource={data} size="small" />
      <Drawer
        title="Manage Users"
        width={420}
        onClose={updateDrawer}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={updateDrawer} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                form.submit();
              }}
              type="primary"
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFormSubmit}
          values={formValues}
          hideRequiredMark
        >
          <Row gutter={16}>

            <Col span={24}>
              <Form.Item name="isFormEdit" hidden={true}>

              </Form.Item>
            </Col>


            <Col span={24}>
              <Form.Item name="uniId" hidden={true}>

              </Form.Item>
            </Col>
           
            <Col span={24}>

            <Form.Item
        name="name"
        label="User Name"
        rules={[
          {
            required: true,
            message: "Please input user name!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      </Col>

      <Col span={24}>

      <Form.Item
        name="mobile"
        label="Mobile No"
        rules={[
          {
            required: true,
            message: "Please input Mobile no!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      </Col>

      <Col span={24}>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            message: "Please input Email!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      </Col>

      <Col span={24}>

      <Form.Item
        name="address"
        label="Address"

      >
        <Input />
      </Form.Item>
      </Col>

      <Col span={24}>


      <Form.Item
        name="company"
        label="Company"

      >
        <Input />
      </Form.Item>
      </Col>

      <Col span={24}>

      <Form.Item
        name="tax"
        label="Tax ID"

      >
        <Input />
      </Form.Item>
      </Col>

      <Col span={24}>

      <Form.Item
        name="password"
        label="Password"
        rules={[
         
        ]}
      >
        <Input.Password />
      </Form.Item>
      </Col>

      <Col span={24}>


      <Form.Item
        name="discount"
        label="User Discount"
        rules={[
         
        ]}
      >
        <Input />
      </Form.Item>
      </Col>

      <Col span={24}>

      <Form.Item
        name="email_group"
        label="Email Group"
        rules={[
         
        ]}
      >
        <SectionSelect placeholder={"Select Email Group"} data={emailGroup} onSelect={onEmailGroupSelect} selected={editData?.email_group? editData?.email_group.group_ids.split(",") : []}/>
      </Form.Item>
      </Col>

          </Row>
        </Form>
      </Drawer>
      <ModalFilterCommon
              visible={showFilter}
              handleClose={toggleFilterDialog}
            />
    </div>
  );
}
const mapStateToProps = (state) => ({
  data: state.users.list,
  REFRESH: state.levent.USERS_REFRESH,
  emailGroup: state.emailgroup.list

});

const mapDispatchToProps = (dispatch) => ({
  getData: (data) => dispatch(fetchData(data)),
  addData: (data) => dispatch(createData(data)),
  updateData: (data) => dispatch(putData(data)),
  getEmailGroup: () => dispatch(fetchEmailGroup()),

});

export default connect(mapStateToProps, mapDispatchToProps)(PageUsers);
