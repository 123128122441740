import {requestStart, requestFinish, getApi,postApi,updateApi} from './common';
import {API_BASE_URL , PATH_PRODUCTS as PATH_CONTEXT, PATH_API_COMMON, PATH_MANAGE, PATH_API_MANAGE} from '../config';

const ACTION_META_NAME = "PRODUCT";

export  function fetchData(reqData, type= "") {

  return async function(dispatch) {
   

    let QUERY_PARAMS = '';

    if(reqData){

      if(reqData.query){
        QUERY_PARAMS = `query=${reqData.query}`;
      }

      if(reqData.category){
      if(reqData.category.length > 0){
        QUERY_PARAMS = `${QUERY_PARAMS}&searchIn=${reqData.category[0]}`;
      }

      if(reqData.category.length > 1){
        QUERY_PARAMS = `${QUERY_PARAMS}&searchIn=${reqData.category[1]}`;
      }

    

      if(reqData.category.length > 2){
        QUERY_PARAMS = `${QUERY_PARAMS}&searchIn=${reqData.category[2]}`;
      }
    }
    if(reqData.sku){
      QUERY_PARAMS = `${QUERY_PARAMS}&sku=${reqData.sku}`;
    }

    if(reqData.only_deactive){
      QUERY_PARAMS = `${QUERY_PARAMS}&only_deactive=true`;
    }
      if(reqData.page){
        QUERY_PARAMS = `${QUERY_PARAMS}&page=${reqData.page}`;
      }else{
        QUERY_PARAMS = `${QUERY_PARAMS}&page=0`;

      }
   
      if(reqData.pageSize){
        QUERY_PARAMS = `${QUERY_PARAMS}&limit=${reqData.pageSize}`;
      }else{
        QUERY_PARAMS = `${QUERY_PARAMS}&limit=200`;

      }
    }

    const data = await getApi(dispatch, {
      url : `${API_BASE_URL}${PATH_API_MANAGE}products/${type}?${QUERY_PARAMS}`
    });
    console.log("api called", data)

    if(data!=null){
      dispatch({
        type: ACTION_META_NAME+'_LIST',
        data:data,
        receivedAt: Date.now()
      });
    }

    dispatch({
      type:'NOTIFICATION_HIDE',
      data:{
        title:'',
        type:'success',
        body:''
      }
    });
 
  }
}



export  function createData(reqData, type= "") {

  return async function(dispatch) {
   

    const data = await postApi(dispatch, {
      url : `${API_BASE_URL}${PATH_MANAGE}${PATH_CONTEXT}`,
      body: reqData
    });

    if(data!=null){

      dispatch({
        type:'NOTIFICATION_SHOW',
        data:{
          title: ACTION_META_NAME + ' Created',
          type:'success',
          body:''
        }
      });

      dispatch({
        type:ACTION_META_NAME+'_CREATED',
        data:data,
        receivedAt: Date.now()
      });

      dispatch({
        type:'FIRE_EVENT',
        data:{
          event: ACTION_META_NAME+'_REFRESH'
        }
      });


    }
 
  }
}

export  function putData(productId, reqData) {

  return async function(dispatch) {
   


    const data = await updateApi(dispatch, {
      url : `${API_BASE_URL}${PATH_MANAGE}${PATH_CONTEXT}/${productId}`,
      body: reqData
    });

    if(data!=null){

      dispatch({
        type:'NOTIFICATION_SHOW',
        data:{
          title:'Product Updated',
          type:'success',
          body:''
        }
      });

      dispatch({
        type: ACTION_META_NAME+'_UPDATED',
        data:data,
        receivedAt: Date.now()
      });

      dispatch({
        type:'FIRE_EVENT',
        data:{
          event: ACTION_META_NAME+'_REFRESH'
        }
      });


    }
 
  }
}


export  function productBypass(reqData, type= "") {

  return async function(dispatch) {
   

    const data = await postApi(dispatch, {
      url : `${API_BASE_URL}api-public/bypass/${reqData.id}`,
      body: reqData
    });

    if(data!=null){

      dispatch({
        type:'NOTIFICATION_SHOW',
        data:{
          title: ACTION_META_NAME + ' Created',
          type:'success',
          body:''
        }
      });

      dispatch({
        type:ACTION_META_NAME+'_CREATED',
        data:data,
        receivedAt: Date.now()
      });

      dispatch({
        type:'FIRE_EVENT',
        data:{
          event: ACTION_META_NAME+'_REFRESH'
        }
      });


    }
 
  }
}