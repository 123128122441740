import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import {
  Modal,
  List,
  Avatar,
  Button,
  Tag
} from "antd";


const _ = require('lodash');

function ModalSync({
  showModal,
  toggleModal,
  data,
  handleProductSync,
  etsySyncId,
  etsyGoldSyncId,
  shopifySyncId,
  shopifyTJSyncId
}) {

  console.log("sync view", data);

  const [isLoading, setLoading] = useState(false);

  const [syncType, setSyncType] = useState();

  // Jewels & Chains Etsy
  // Jewels & Chains Shopify
  // Gold Etsy
  // Tresor Shopify

  const itemList = [
    {
      id: 1,
      alt: 'Jewels & Chains Etsy',
      color: '#f56a00',
      name: 'Item 1',
      status: 'Active',
      link: 'https://example.com/item1',
      typeId: 'etsy_listing_id'
    },
    {
      id: 2,
      alt: 'Jewels & Chains Shopify',
      color: '#00a2ae',
      name: 'Item 2',
      status: 'Inactive',
      link: 'https://example.com/item2',
      typeId: 'shopify_id'

    },
    {
      id: 3,
      alt: 'Gold Etsy',
      color: '#ffbf00',
      name: 'Item 2',
      status: 'Inactive',
      link: 'https://example.com/item2',
      typeId: 'etsy_gold_listing_id'

    },
    {
      id: 4,
      alt: 'Tresor Shopify',
      color: '#00a2ae',
      name: 'Item 2',
      status: 'Inactive',
      link: 'https://example.com/item2',
      typeId: 'shopify_tj_id'

    },
    // Add more items as needed
  ];

  const handleSyncStatus = (data, type) => {


    const isSynced = data?.[type];

    console.log(type, isSynced)
    return isSynced

  }

  const getProductId = (data, type) => {


    const isSynced = data?.[type];

    console.log(type, isSynced)
    return isSynced

  }

  const getProductUrl = (data, type) => {

    let baseUrl = 'https://jewelsandchains.com/products/';
    let productHandle = data?.model;


    if (type === "etsy_listing_id") {

      baseUrl = 'https://www.etsy.com/in-en/listing/';
      productHandle = data?.[type]
    }

    if (type === "etsy_gold_listing_id") {

      baseUrl = 'https://www.etsy.com/in-en/listing/'
      productHandle = data?.[type]



    }



    return `${baseUrl}${productHandle}`

  }

  const handleSyncClick = (item) => {

    console.log("click handle", item.typeId)

    handleProductSync(data, item.typeId);




  }

  useEffect(() => {


    if (etsySyncId === 0) {
      setLoading(false);
      setSyncType('');
    } else if (etsyGoldSyncId === 0) {
      setLoading(false);
      setSyncType('');
    } else if (shopifySyncId === 0) {
      setLoading(false);
      setSyncType('');
    } else if (shopifyTJSyncId === 0) {
      setLoading(false);
      setSyncType('');
    } 
    

    if (etsySyncId !== 0 && etsySyncId === data.uniId) {
      setLoading(true);
      setSyncType('etsy_listing_id');
    } else if (etsyGoldSyncId !== 0 && etsyGoldSyncId === data.uniId) {
      setLoading(true);
      setSyncType('etsy_gold_listing_id');
    } else if (shopifySyncId !== 0 && shopifySyncId === data.uniId) {
      setLoading(true);
      setSyncType('shopify_id');
    }else if (shopifyTJSyncId !== 0 && shopifyTJSyncId === data.uniId) {
      setLoading(true);
      setSyncType('shopify_tj_id');
    }

  }, [etsySyncId, etsyGoldSyncId, shopifySyncId, shopifyTJSyncId])

  return (
    <div>
      <Modal
        title="Sync"
        visible={showModal}
        onOk={() => { toggleModal(false) }}
        onCancel={() => { toggleModal(false) }}

        width={600}
        maskClosable={false}
      >
        <List
          itemLayout="horizontal"
          dataSource={itemList}
          renderItem={(item) => (


            <List.Item
              actions={[<Button loading={isLoading && syncType === item.typeId} type="primary" onClick={() => handleSyncClick(item)}> {isLoading && syncType === item.typeId ? 'Syncing' : 'Sync'}</Button>]}
            >
              <List.Item.Meta
                description={<a target="_blank" href={getProductUrl(data, item.typeId)}>{getProductId(data, item.typeId)}</a>}
                title={item.alt}/>




              <div>{!_.isNil(handleSyncStatus(data, item.typeId)) ? (<Tag icon={<CheckCircleOutlined />} color="success">
                  Synced
                </Tag>) : (<Tag icon={<ExclamationCircleOutlined />} color="warning">
                  Not Synced
                </Tag>)} </div>
            </List.Item>



          )}
        />
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  etsySyncId: state.sync.etsySyncId,
  etsyGoldSyncId: state.sync.etsyGoldSyncId,
  shopifySyncId: state.sync.shopifySyncId,
  shopifyTJSyncId: state.sync.shopifyTJSyncId

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSync);
