import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import { fmtDate } from "../utils/datetime";

import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
} from "antd";
import {
  EyeOutlined
} from "@ant-design/icons";

import { Table, Tag, Space, Popconfirm, Switch, InputNumber } from "antd";

import OrderDetail from "../component/OrderDetail";


import { PageHeader } from "antd";
import { useHistory } from "react-router-dom";

import {
  createData as createCMS,
  fetchData as fetchCMS,
  putData as putCMS,
} from "../actions/cms";
import { API_BASE_URL } from "../config";


const CMS_TYPE = "order";

function PageOrder({
  data,
  getData,
  addCMS,
  updateCMS,
  REFRESH,
  lookup,
  addUnit,
  unitData,
  bypassData,
  updateProduct,
  getCMS,
}) {
  const [showFilter, toggleFilter] = React.useState(false);

  const [showDrawer, toggleDrawer] = React.useState(false);

  const [formValues, updateFormValues] = useState({
    role_type: 1,
    isFormEdit: false,
  });
  const [filterData, setFilterData] = useState({ query: "" });

  const [viewOrderData, setViewOrderData] = useState({});

  const history = useHistory();

  const updateDrawer = (showDrawer = false) => {
    toggleDrawer(showDrawer);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    getCMS({ }, "orders");
    updateDrawer(false);
  }, [REFRESH]);

  const onFormSubmit = (values) => {
    console.log("form data", values);

    let data = {
      ...values,
      image_url: values?.image_url?.url
        ? values.image_url.url
        : values.image_url,
    };

    // console.log("toggle filter dialog called", data);


        if (data.isFormEdit) {
          updateCMS({ ...data, coupon_id: data.uniId }, data.uniId, CMS_TYPE);
        } else {
          addCMS(data, CMS_TYPE);
        }
      
    


  };

  const onUpdateItem = (data, updates) => {


      updateCMS({ ...data, ...updates },data.coupon_id,  CMS_TYPE);

    
  };

  const onActionItem = (item, data, index) => {

 

  
      form.setFieldsValue({
        title: data.title,
        detail: data.detail,
        slug: data.slug,
        uniId: data.coupon_id,
        isFormEdit: true,
        image_url: data.image_url,
      });
    

    updateDrawer(true);

  };

  const updateOrder = (orderId) => {

    updateCMS({}, orderId, CMS_TYPE);


  }

  const handleViewOrderClick = (data) => {
    setViewOrderData(data);
    updateDrawer(true);

  }

  const   columns = [
   
    {
      title: 'Order Id',
      dataIndex: 'order_id',
      key: 'order_id',
      width:100
    },
    {
      title: 'User Info',
      dataIndex: 'user_info',
      key: 'user_info',
      render:(item, data)=> <div>{item.name} ( {item.mobile } )<br></br>{item.address}</div>
    },
    {
      title: 'Items',
      dataIndex: 'items',
      key: 'items',
      render:(item, data)=> <div>{item.length}</div>,
      responsive: ['md'],

    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
      responsive: ['md'],
    },
    {
      title: 'Amount',
      dataIndex: 'subtotal',
      key: 'subtotal',
      responsive: ['md'],
      render: (text) => <span><strong>${text}</strong></span>,

    },
    {
      title: 'Dated',
      dataIndex: 'created',
      key: 'created',
      responsive: ['md'],
      width:200

    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      responsive: ['md'],
      width:400
    },
    {
      title: 'Completed',
      key: 'order_status',
      render: (order_status, record,index) => (
        <Space size="middle">
         <Switch checked={record.order_status === 700} disabled={record.order_status === 700} onChange={()=> {
          //  onPrivateItem(item,record,index)
          updateOrder(record.order_id);
          
         }} />
        </Space>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (record) => (
        <Space size="middle">
          <a onClick={() => handleViewOrderClick(record)}>View Order</a>
          {/* <a>Delete</a> */}
        </Space>
      ),
      width:100

    },
  ];
  const [newItem, addNewItem] = useState("");
  const onNameChange = (event) => {
    addNewItem(event.target.value);
  };

  const addItem = (data) => {
    console.log(data);

    if (data != "") addUnit({ name: data });

    addNewItem("");
  };

  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        title={`${CMS_TYPE}s`}
        className="site-page-header"
        extra={[
          <Input.Search
          allowClear
          style={{ width: "400px" }}
          defaultValue=""
          placeholder="Search anything.."
          onChange={(e) => {
            console.log(e.target.value);
            setFilterData({ ...filterData, query: e.target.value.toLowerCase() });
          }}
        />
        ]}
      ></PageHeader>
      <Table columns={columns} 
      dataSource={data?.filter(
        (item) =>
          (item?.subtotal?.toString())?.includes(filterData.query) || 
          (item?.user_info?.name?.toLowerCase())?.includes(filterData.query) ||
          (item?.user_info?.mobile?.toLowerCase())?.includes(filterData.query) ||
          (item?.user_info?.address?.toLowerCase())?.includes(filterData.query) ||
          (item?.remark?.toLowerCase())?.includes(filterData.query) ||
          (item?.created?.toString())?.includes(filterData.query)

      )} 
      size="small" />
      <Drawer
        title={`View Order`}
        width={1200}
        onClose={() => updateDrawer(false)}
        visible={showDrawer}
        maskClosable={false}
        bodyStyle={{ paddingBottom: 80 }}
      >
     <OrderDetail data={viewOrderData} />
      </Drawer>
    </div>
  );
}
const mapStateToProps = (state) => ({
  REFRESH: state.levent.CMS_REFRESH,
  data: state.cms?.[CMS_TYPE],
});

const mapDispatchToProps = (dispatch) => ({

  updateCMS: (data, uniId, type) => dispatch(putCMS(data, uniId,type)),
  addCMS: (data, type) => dispatch(createCMS(data, type)),
  getCMS: (data, type) => dispatch(fetchCMS(data, CMS_TYPE)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageOrder);
