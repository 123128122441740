import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Input } from 'antd';

import { Modal, Button, Switch } from 'antd';
import { connect } from "react-redux";
import { Cascader, Select, Checkbox, AutoComplete, Upload, Space, Divider } from 'antd';

const ModalFilterProduct = ({ visible, handleClose, cascadeCategory }) => {



    const [form] = Form.useForm();

    const onFinish = (values) => {

        handleClose(form.getFieldsValue());

    };

    const onOk = (values) => {


        handleClose(form.getFieldsValue());
    };


    useEffect(()=>{

        if(visible){
            form.resetFields();
        }
    },[visible])

    return (
        <>

            <Modal title="Search Product" 
            visible={visible} onOk={onOk} 
            onCancel={() => handleClose()}
            >
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                    
                >

                    <Form.Item
                        name="query"
                        label="keywords"

                    >
                        <Input placeholder="name, model, description etc" />
                    </Form.Item>

                    <Form.Item
                        name="sku"
                        label="Sku"

                    >
                        <Input placeholder="sku" />
                    </Form.Item>

                    <Form.Item
                        name="only_deactive"
                        label="Inactive"

                    >
                        <Switch
                                />
                              </Form.Item>

                    <Form.Item
                        name="category"
                        label="Category"

                    >
                        <Cascader options={cascadeCategory} changeOnSelect />
                    </Form.Item>


                    <Col span={4}>
                        <Button

                            style={{ marginTop: 30, display: 'none' }}
                            type="primary"
                            htmlType="submit"

                            size={"medium"}
                        />
                    </Col>
                </Form>

            </Modal>
        </>
    );
};


const mapStateToProps = (state) =>
({
    products: state.products,
    category: state.category.data,
    cascadeCategory: state.category.cascade

});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalFilterProduct);