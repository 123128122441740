
import React, { useState, useEffect } from "react";

import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error('Image must smaller than 10MB!');
  }
  return isJpgOrPng && isLt2M;
}

export default function SingleUpload ({value, onChange}){

  console.log(value);

  const [loading, toggleLoading] = useState(false);

  const [imageUrl, setImageUrl] = useState(value);

 const  handleChange = info => {
  if (info.file.status === 'uploading') {

    toggleLoading(!loading);
    return;
  }
  if (info.file.status === 'done') {

    console.log(info);
    // Get this url from response in real world.
    getBase64(info.file.originFileObj, imageUrl =>{

     

      toggleLoading(!loading);
      setImageUrl(imageUrl);

      onChange(info.fileList[0].response.url);
    }
      
    );
  }
  };

    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <Upload
        name="file"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        maxCount={1}
        action={`${process.env.REACT_APP_API_MEDIA_URL}`}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {value ? <img src={value} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    );
  
}