import {requestStart, requestFinish, getApi,postApi,updateApi} from './common';
import {API_BASE_URL , PATH_MANAGE as PATH_CONTEXT,} from '../config';
import { fetchLookup } from './common';

const ACTION_META_NAME = "CMS";

export  function fetchData(reqData, type= "") {

  return async function(dispatch) {
   

    let QUERY_PARAMS = '';

    if(reqData.query){
      QUERY_PARAMS = `?query=${reqData.query}`;
    }

    const data = await getApi(dispatch, {
      url : `${API_BASE_URL}${PATH_CONTEXT}${type}${QUERY_PARAMS}`
    });
    console.log("api called", data)

    if(data!=null){
      dispatch({
        type: type.toUpperCase()+'_LIST',
        data:data,
        receivedAt: Date.now()
      });
    }

    dispatch({
      type:'NOTIFICATION_HIDE',
      data:{
        title:'',
        type:'success',
        body:''
      }
    });
 
  }
}



export  function createData(reqData, type= "") {

  return async function(dispatch) {
   

    const data = await postApi(dispatch, {
      url : `${API_BASE_URL}${PATH_CONTEXT}${type}`,
      body: reqData
    });

    if(data!=null){

      dispatch({
        type:'NOTIFICATION_SHOW',
        data:{
          title: ACTION_META_NAME + ' Created',
          type:'success',
          body:''
        }
      });

      dispatch({
        type:ACTION_META_NAME+'_CREATED',
        data:data,
        receivedAt: Date.now()
      });

      dispatch({
        type:'FIRE_EVENT',
        data:{
          event: ACTION_META_NAME+'_REFRESH'
        }
      });


      dispatch(fetchLookup());

    }
 
  }
}



export  function putData(reqData, uniId,  type= "" ) {

  return async function(dispatch) {
   

    const data = await updateApi(dispatch, {
      url : `${API_BASE_URL}${PATH_CONTEXT}${type}/${uniId}`,
      body: reqData
    });


 
      if(data!=null){

        dispatch({
          type:'NOTIFICATION_SHOW',
          data:{
            title: ACTION_META_NAME + ' Updated',
            type:'success',
            body:''
          }
        });
  
        dispatch({
          type:ACTION_META_NAME+'_UPDATED',
          data:data,
          receivedAt: Date.now()
        });
  
        dispatch({
          type:'FIRE_EVENT',
          data:{
            event: ACTION_META_NAME+'_REFRESH'
          }
        });
  
  
        dispatch(fetchLookup());
  
      }

    
 
  }
}