

const defaultProductPayload = {
  description: "",
  model: null,
  name: "",
  sku: null,
  subcategory: [{}],
  lowcategory: [{}],
  images: [],
  variant: [],
  tags: [],
  material : [],
  category: [{}],
  unit_meta:{name:''}
};
const products = (state = {list:[], isEdit : false, product : defaultProductPayload, pagination:{
  current : 1, pageSize :10, total: 1000
}, filter : {} }, action) => {
  switch (action.type) {

    case 'PRODUCT_LIST':

    state.list = action.data;
    return state;


    case 'PRODUCT_EDIT':

      state.product = action.data;
      state.isEdit = true;

      return state;

    case 'PRODUCT_CREATE':

      state.product =defaultProductPayload;
      state.isEdit = false;

      return state;
    case 'PRODUCT_PAGE_CHANGE':

      return {
        ...state,
        pagination : {
          current :  action.data.current,
          pageSize: action.data.pageSize,
          total: action.data.total
        }
      }

      case 'PRODUCT_FILTER':

        return {
          ...state,
          filter : action.data
        }
    default:
      return state
  }
}
export default products